import moment from "moment";
import Vue from "vue";
import store from "../store";

Vue.filter("dateformat", (arg) => {
  return moment(arg).format("Do MMM YYYY");
});
Vue.filter("dateformatDDMMYY", (arg) => {
  return moment(arg).format("DD/MM/YY");
});

Vue.filter("dayDateFormat", (arg) => {
  return moment(arg).format("dddd, MMMM Do YYYY");
});
Vue.filter("reportDateformat", (arg) => {
  return moment(arg).format("D MMM YYYY");
});
Vue.filter("calendarFormat", (arg) => {
  return moment(arg).format("dddd, Do MMM");
});
Vue.filter("calendarSimpleDate", (arg) => {
  return moment(arg).format(" D ");
});

Vue.filter("calendarYearFormat", (arg) => {
  return moment(arg).format("ddd, Do MMM, YY");
});

Vue.filter("dayFormat", (arg) => {
  return moment(arg).format("DD-MMM-YYYY");
});

Vue.filter("monthFormat", (arg) => {
  return moment(arg).format("MMMM-YYYY");
});

Vue.filter("timeStamp", (arg) => {
  return moment
    .utc(arg)
    .local()
    .format("Do MMM YYYY hh:mm a");
});
Vue.filter("timeStampOrignal", (arg) => {
  return moment(arg).format("Do MMM YYYY hh:mm a");
});

Vue.filter("timeStampOrignal", (arg) => {
  return moment(arg).format("Do MMM YYYY hh:mm a");
});

Vue.filter("timetocode", (arg) => {
  return moment
    .utc(arg)
    .local()
    .format("DDYYMMHH");
});

Vue.filter("timeFormat", (arg) => {
  return moment(
    moment().format("YYYY-MM-DD") + " " + arg,
    "YYYY-MM-DD HH:mm:ss"
  ).format("hh:mm a");
});

Vue.filter("monthNumToName", (number) => {
  return moment()
    .month(number)
    .format("MMMM");
});

Vue.filter("dateToDayname", (arg) => {
  return moment(arg).format("dddd");
});

Vue.filter("toCurrency", (value) => {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: store.getters.currencyCode,
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("toCurrencyWithoutCode", (value) => {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: store.getters.currencyCode,
    minimumFractionDigits: 0,
  });
  let amountWithoutCurrency = formatter.format(value).replace(/[^0-9.,]/g, '');
  return amountWithoutCurrency;
});



Vue.filter("numberFormatter", (value, digit) => {
  digit = digit ? digit : 6;
  if (typeof value !== "number") {
    return value;
  }
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: digit,
  }).format(value);
});

Vue.filter("toFixedTrunc", (x, n) => {
  const v = (typeof x === "string" ? x : x.toString()).split(".");
  if (n <= 0) return v[0];
  let f = v[1] || "";
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += "0";
  return `${v[0]}.${f}`;
});
Vue.filter("formatDecimalNumber", (value) => {
  const decimalPart = value - Math.floor(value); // Get the decimal part

  if (decimalPart > 0) {
    // If the decimal part is greater than 0, display it with decimals
    return value.toFixed(2);
  } else {
    // Otherwise, display it without decimals
    return value.toFixed(0);
  }
});


Vue.filter( "formatFileSize",(sizeInKB)  => {
  if (sizeInKB == null || isNaN(sizeInKB)) {
    return "";
  }

  const units = ['Bytes','KB', 'MB', 'GB', 'TB'];
  let size = sizeInKB;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
});

Vue.filter("ordinal", (number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100; // Get last two digits

  // Determine suffix based on the number
  const suffix = suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];

  return number + suffix;
});

Vue.filter("timeDuration", (input) => {

  const seconds = Number(input);

  // If the input is not a valid number, return '0s'
  if (isNaN(seconds) || seconds < 0) return "0s";


  const hours = Math.floor(seconds / 3600); // Calculate hours
  const minutes = Math.floor((seconds % 3600) / 60); // Calculate minutes
  const remainingSeconds = seconds % 60; // Calculate remaining seconds

  // Build the output string
  let result = "";
  if (hours > 0) {
    result += `${hours} hr${hours > 1 ? "s" : ""}`; // Add hours if > 0
  }
  if (minutes > 0 || hours > 0) { // Show minutes if there are hours
    result += ` ${minutes} min${minutes > 1 ? "s" : ""}`; // Add minutes if > 0
  }
  result += ` ${remainingSeconds} sec${remainingSeconds !== 1 ? "s" : ""}`; // Always show seconds

  return result.trim(); // Trim to remove any leading/trailing spaces
});


export default Vue.filter;
